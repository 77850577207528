import store from '@/store'
import { createProduct, uuid } from "../../../aws/Api"

export const useProduct = () => {
  // add product to database
  const addProductToDatabase = async (product) => {
    product.business_id = "5a25a404-1d50-4134-aa66-f9d0e5622ab3",
    product.product_id = uuid()
    await createProduct(product)
    store.commit("app-products/UPDATE_PRODUCTS", [...store.state["app-products"].products, product]);
  }

  // edit product to database
  const editProductInDatabase = async (product) => {
    await createProduct(product)
  }

  return {
    addProductToDatabase,
    editProductInDatabase
  }
}